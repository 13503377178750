<template>
  <div class="login-page page">
    <div class="wellcome-row">欢迎，来到裕策生物</div>
    <div class="sms-title">短信验证登录</div>
    <van-form>
      <van-field
        v-model="phone"
        type="tel"
        name="phone"
        placeholder="请输入手机号码"
        clearable
      />
      <van-field
        v-model="sms"
        name="sms"
        center
        clearable
        placeholder="请输入验证码"
      >
        <template #button class="sendSms-row">
          <span
            class="btn-sendSms"
            v-show="!showTimeDown"
            @click="handerShowTimeDown"
            >获取验证码</span
          >
          <van-count-down
            v-show="showTimeDown"
            :auto-start="false"
            :time="time"
            class="count-down-row"
            @finish="finish"
            format="ss"
            ref="countDown"
          >
            <template #default="timeData">
              重新发送<span class="block">{{ timeData.seconds }}S</span>
            </template>
          </van-count-down>
        </template>
      </van-field>
      <div class="btn-submit-row">
        <van-button
          round
          block
          type="info"
          color="#00B580"
          class="btn-submit"
          native-type="button"
          @click="submitLogin"
          >登录</van-button
        >
      </div>
      <van-row type="flex" align="center">
        <van-col offset="1" span="2">
          <van-checkbox v-model="checked" icon-size="15px"></van-checkbox>
        </van-col>
        <van-col span="21">
          <div class="agrement-row">
            我已阅读并同意<span @click="toAgrementDetail" class="agrement-title"
              >《裕策隐私保护政策、检测知情同意书》
            </span>
          </div>
        </van-col>
      </van-row>
    </van-form>
  </div>
</template>
<script>
import { validatePhone, Local } from "@/utils/common.js";
export default {
  data() {
    return {
      showTimeDown: false,
      time: 60000,
      phone: "",
      sms: "",
      checked: false,
      pls_token: this.$route.query.pls || "",
    };
  },
  methods: {
    submitLogin() {
      if (!this.checkPhone()) {
        return;
      }
      if (!this.checked) {
        this.$toast.fail("请同意裕策隐私保护政策、检测知情同意书");
        return;
      }
      
      this.requestLogin();
    },
    requestSendSmsCcode() {
      let params = {
        phone: this.phone,
        pls_token: this.pls_token,
      };
      this.$request.post(this.$api.sendSmsCcode, params);
    },
    requestLogin() {
      let params = {
        phone: this.phone,
        vcode: this.sms,
      };
      this.$request.post(this.$api.login, params).then(() => {
        // 此处只接收成功数据，失败数据不返回
        this.$router.push({ path: `/testRequestForm`,query:{ phone:this.phone } });
      });
    },
    finish() {
      this.showTimeDown = false;
      this.reset();
    },
    /**
     * 描述 校验手机号码是否合格 和是否输入  手机号码只要是1开头 11位长度即可
     * @author zhongchaoliang
     * @date 2021-07-02
     * @returns {any}
     */
    checkPhone() {
      if (!this.phone) {
        this.$toast.fail("请输入手机号码");
        return false;
      }
      if (!validatePhone(this.phone)) {
        this.$toast.fail("手机号码格式错误");
        return false;
      }
      return true;
    },
    handerShowTimeDown() {
      if (!this.pls_token) {
        this.$toast.fail("非法请求");
        return;
      }
      if (!this.checkPhone()) {
        return;
      }
      this.showTimeDown = true;
      this.start();
      this.requestSendSmsCcode();
    },
    start() {
      this.$refs.countDown.start();
    },
    reset() {
      this.$refs.countDown.reset();
    },
    toAgrementDetail() {
      this.$router.push("/agrementDetail");
    },
  },
  mounted() {
    // console.log(this.$route.query);
    let testRequestFormData = Local.get("testRequestFormData") || "";
    if (testRequestFormData) {
      Local.remove("testRequestFormData");
    }
  },
};
</script>
<style lang="less" scoped>
// @import "~assets/less/variable.less";
.login-page {
  /deep/ .van-checkbox__icon--checked .van-icon {
    background-color: @theme-color;
    border-color: @theme-color;
  }
  .btn-sendSms {
    font-size: 30px;
    font-weight: 400;
    color: @theme-color;
  }
  .wellcome-row,
  .sms-title {
    padding-left: 32px;
    color: #0a0a0a;
    opacity: 1;
  }
  .wellcome-row {
    padding-top: 64px;
    margin-bottom: 90px;
    font-size: 48px;
    font-weight: 600;
  }
  .sms-title {
    font-size: @font-size-large;
    font-weight: 500;
    margin-bottom: 40px;
  }
  .btn-submit-row {
    margin: 56px 32px 23px;
    .van-button__text {
      font-size: 34px;
      color: #f2f2f2;
    }
  }
  .count-down-row {
    width: 200px;
    text-align: center;
    font-size: @font-size-medium;
    color: @theme-color;
  }
  .agrement-row {
    font-size: @font-size-small;
    .agrement-title {
      color: @theme-color;
    }
  }
}
</style>