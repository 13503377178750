export const validatePhone = (phone) => {
    if (!(/^((\+|00)86)?1\d{10}$/.test(phone))) {
        // 只要1开头 11位长度
        return false;
    } else {
        return true;
    }
}
const localStorage = window.localStorage;
export const Local = {
    get(key) {
        if (key) return JSON.parse(localStorage.getItem(key))
        return null
    },
    set(key, val) {
        const setting = arguments[0]
        if (Object.prototype.toString.call(setting).slice(8, -1) === 'Object') {
            for (const i in setting) {
                localStorage.setItem(i, JSON.stringify(setting[i]))
            }
        } else {
            localStorage.setItem(key, JSON.stringify(val))
        }
    },
    remove(key) {
        localStorage.removeItem(key)
    },
    clear() {
        localStorage.clear()
    }
};